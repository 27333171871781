.container {
  padding: 20px;
  background-color: #f8f9fa;
}

.title {
  text-align: center;
  margin-bottom: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .searchBar {
    width: 70%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }

  .exportButton {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;

    &:hover {
      background-color: #0056b3;
    }
  }
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;

  th, td {
    border: 1px solid #dee2e6;
    padding: 8px;
    text-align: left;
  }

  th {
    background-color: #e9ecef;
  }
}

.linkButton {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  width: 80%;
  max-height: 80vh;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  h2 {
    margin: 0;
  }

  .closeButton {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
}

.modalContent {
  .orderSection {
    margin-bottom: 20px;

    h3 {
      margin-bottom: 10px;
      border-bottom: 1px solid #dee2e6;
      padding-bottom: 5px;
    }

    .table {
      width: 100%;
      border-collapse: collapse;

      th, td {
        border: 1px solid #dee2e6;
        padding: 8px;
        text-align: left;
      }

      th {
        background-color: #e9ecef;
      }
    }
  }
}
