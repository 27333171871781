.carousel-container {
  position: relative;
  margin: 100px; // Corrected margin

  .custom-nav-left,
  .custom-nav-right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem; // Adjust size as needed
    cursor: pointer;
    z-index: 1000; // Ensure they are on top of other elements
    color: #000; // Default color
    transition: all 0.3s ease; // Smooth transition for hover effects

    i {
      font-size: inherit; // Inherit size from parent
    }

    &:hover {
     background-color: orange;
     color:white;
     font-size: 2em;
     border-radius: 50%;
    }

    &:active {
      transform: scale(0.9); // Slightly shrink the icon when pressed
    }
  }

  .custom-nav-left {
    left: 10px; // Adjust as needed
  }

  .custom-nav-right {
    right: 10px; // Adjust as needed
  }
}
/* Base styles for the container */
.container-fluid.vegetable {
  padding: 2rem;

  .container {
    padding: 2rem;

    h1 {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
  }
}

/* Custom navigation controls */
.custom-nav-left, .custom-nav-right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2rem;
  cursor: pointer;
  z-index: 10;
  color: #000; /* Adjust as needed */
}

.custom-nav-left {
  left: 10px;
}

.custom-nav-right {
  right: 10px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .custom-nav-left, .custom-nav-right {
    font-size: 1.5rem;
  }

  .container-fluid.vegetable {
    padding: 1rem;

    .container {
      padding: 1rem;

      h1 {
        font-size: 1.5rem;
      }
    }
  }
}

@media (max-width: 576px) {
  .custom-nav-left, .custom-nav-right {
    font-size: 1.2rem;
  }

  .container-fluid.vegetable {
    padding: 0.5rem;

    .container {
      padding: 0.5rem;

      h1 {
        font-size: 1.2rem;
      }
    }
  }
}
