.dashboard-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dashboard-wrapper-main {
    flex: 3;
    padding-right: 20px;

    h1 {
        font-size: 24px;
        margin-bottom: 20px;
    }

    .file-import {
        display: flex;
        align-items: center;

        input[type="file"] {
            margin-right: 10px;
        }

        button {
            padding: 10px 20px;
            background-color: #4caf50;
            color: white;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            transition: background-color 0.3s;

            &:hover {
                background-color: #45a049;
            }
        }
    }
   
}

.dashboard-wrapper-right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
        padding: 10px 20px;
        background-color: #2196f3;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
            background-color: #1e88e5;
        }
    }
}
