.attributes-manager-container {
    padding: 20px;
    font-family: 'Arial', sans-serif;
}

h1 {
    text-align: center;
    font-size: 2.5rem;
    color: #333;
}

.message {
    margin: 10px auto;
    text-align: center;
    color: #28a745;
    background: #e7f5e6;
    border-radius: 4px;
    padding: 10px;
}

.attribute-form-card {
    background: #fff;
    padding: 20px;
    margin: 20px auto;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;

    form {
        display: flex;
        flex-direction: column;

        label {
            margin-bottom: 5px;
            font-weight: bold;
            color: #555;
        }

        input {
            padding: 10px;
            border: 1px solid #ccc;
            border-radius: 4px;
            margin-bottom: 15px;
            font-size: 1rem;
        }

        .submit-btn {
            padding: 12px;
            background: #007bff;
            color: white;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            transition: background 0.3s ease;
        }

        .submit-btn:hover {
            background: #0056b3;
        }
    }
}

.loading-spinner {
    text-align: center;
    font-size: 1.5rem;
    color: #333;
}

.attributes-list {
    margin-top: 20px;
    list-style: none;
    padding: 0;

    .attribute-card {
        background: #f8f9fa;
        margin-bottom: 10px;
        padding: 15px;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        transition: transform 0.2s ease;

        &:hover {
            transform: translateY(-2px);
        }

        .attribute-details {
            flex: 1;

            .attribute-name {
                font-weight: bold;
                font-size: 1.2rem;
                color: #333;
            }

            .attribute-type {
                font-size: 1rem;
                color: #666;
            }

            .attribute-values {
                margin-top: 5px;
                font-size: 0.9rem;
                color: #777;
            }
        }

        .actions {
            display: flex;
            gap: 10px;

            .edit-btn, .delete-btn {
                padding: 8px 12px;
                border: none;
                border-radius: 4px;
                cursor: pointer;
                transition: background 0.3s ease;
            }

            .edit-btn {
                background: #ffc107;
                color: #fff;
            }

            .edit-btn:hover {
                background: #e0a800;
            }

            .delete-btn {
                background: #dc3545;
                color: #fff;
            }

            .delete-btn:hover {
                background: #c82333;
            }
        }
    }
}
.dropdown-container {
    margin-bottom: 1em;

    // Common styles for select elements
    select {
        width: 100%; // Full width
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        background-color: #fff;
        font-size: 16px; // Font size for readability
        appearance: none; // Remove default styling
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10"><polygon points="0,0 10,0 5,10" fill="%23ccc"/></svg>'); // Custom arrow icon
        background-repeat: no-repeat;
        background-position: right 10px center;
        background-size: 10px;

        &:focus {
            outline: none; // Remove default focus outline
            border-color: #007bff; // Change border color on focus
            box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); // Add shadow for focus effect
        }

        option {
            padding: 10px; // Add padding for options
        }
    }

    // Add some spacing for better layout
    & + & {
        margin-top: 10px; // Margin between consecutive dropdowns
    }
}
.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    .page-btn {
        background-color: #f5f5f5;
        border: 1px solid #ddd;
        color: #333;
        padding: 8px 12px;
        margin: 0 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #eee;
        }

        &.active {
            background-color: #007bff;
            color: #fff;
            border-color: #007bff;
        }

        &:focus {
            outline: none;
        }
    }
}
