.pagination-nav {
  display: flex;
  justify-content: center; // Center the pagination items
  align-items: center; // Vertically center the items
  margin: 1rem auto; // Center the pagination in its container with some top and bottom margin
}

.pagination {
  display: flex;
  justify-content: center; // Center the pagination items
  align-items: center; // Vertically center the items
  max-width: 80vw; // Set max width to 80% of the viewport width
  padding: 0.5rem; // Add some padding around the pagination
  background-color: #ffffff; // White background for the pagination
  border-radius: 8px; // Slightly rounded corners
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); // Add a shadow for depth

  .page-item {
    margin: 0 4px; // Space between page items
    list-style: none; // Remove bullet points

    &.disabled {
      pointer-events: none; // Disable clicking on disabled items
      opacity: 0.5; // Reduce opacity for disabled items
    }

    &.active {
      font-weight: bold; // Highlight active page
      background-color: #007bff; // Change background for active item
      color: white; // Change text color for active item
      border-radius: 4px; // Rounded corners
      box-shadow: 0 0 10px rgba(0, 123, 255, 0.5); // Add a shadow to active item
    }

    .page-link {
      display: inline-block;
      padding: 0.5rem 1rem; // Adjust padding for links
      border: 1px solid transparent; // Make border transparent initially
      color: #007bff; // Link text color
      text-decoration: none; // Remove underline
      border-radius: 4px; // Rounded corners
      transition: background-color 0.3s, border-color 0.3s; // Smooth transitions for hover effects
      cursor: pointer; // Change cursor to pointer

      &:hover {
        background-color: #e9f5ff; // Change background on hover
        border-color: #007bff; // Show border color on hover
      }

      &:focus {
        outline: none; // Remove default outline on focus
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); // Add a focus shadow
      }

      &:disabled {
        cursor: not-allowed; // Change cursor for disabled state
        background-color: #f0f0f0; // Light grey background for disabled state
        color: #6c757d; // Grey text for disabled state
      }
    }
  }
}
