.container {
    padding: 20px;
  }
  
  .title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  
    .searchBar {
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 4px;
    }
  
    .exportButton {
      padding: 10px 20px;
      background-color: #007bff;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s;
  
      &:hover {
        background-color: #0056b3;
      }
    }
  }
  
  .totalOrders {
    margin-bottom: 20px;
    font-size: 1.2rem;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  
    th, td {
      border: 1px solid #ddd;
      padding: 8px;
      text-align: left;
    }
  
    th {
      background-color: #f8f9fa;
    }
  
    .linkButton {
      background: none;
      border: none;
      color: #007bff;
      cursor: pointer;
      text-decoration: underline;
      padding: 0;
  
      &:hover {
        color: #0056b3;
      }
    }
  
    select {
      padding: 5px;
      border: 1px solid #ddd;
      border-radius: 4px;
    }
  }
  
  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-height: 80vh;
    overflow-y: auto;
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  
    .closeButton {
      position: absolute;
      top: 10px;
      right: 10px;
      background: none;
      border: none;
      font-size: 1.5rem;
      cursor: pointer;
      color: #aaa;
  
      &:hover {
        color: #000;
      }
    }
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
  
  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  
    h2 {
      margin: 0;
    }
  }
  
  .modalContent {
    .orderSection {
      margin-bottom: 20px;
  
      h3 {
        margin-bottom: 10px;
        border-bottom: 1px solid #dee2e6;
        padding-bottom: 5px;
      }
  
      .table {
        width: 100%;
        border-collapse: collapse;
  
        th, td {
          border: 1px solid #dee2e6;
          padding: 8px;
          text-align: left;
        }
  
        th {
          background-color: #e9ecef;
        }
      }
    }
  }
  