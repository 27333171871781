.category-table-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
  
    h1 {
      text-align: center;
      margin-bottom: 20px;
    }
  
    .settings-form {
      .category-select {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
  
        label {
          margin-right: 10px;
          font-weight: bold;
        }
  
        select {
          padding: 10px 20px;
          border-radius: 8px;
          border: 1px solid #ccc;
          background-color: #fff;
          font-size: 16px;
          cursor: pointer;
          transition: all 0.3s;
  
          &:focus {
            outline: none;
            border-color: #4caf50;
            box-shadow: 0 0 5px rgba(76, 175, 80, 0.5);
          }
  
          &:hover {
            border-color: #4caf50;
          }
        }
      }
  
      .category-table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 20px;
  
        th,
        td {
          border: 1px solid #ddd;
          padding: 8px;
          text-align: left;
        }
  
        th {
          background-color: #f2f2f2;
          font-weight: bold;
        }
  
        .item-image {
          width: 50px;
        }
  
        .form-control {
          width: 100%;
          padding: 5px;
          border: 1px solid #ccc;
          border-radius: 4px;
        }
      }
  
      .submit-button {
        background-color: #4caf50;
        color: white;
        padding: 10px 20px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s;
  
        &:hover {
          background-color: #45a049;
        }
      }
    }
  }
  