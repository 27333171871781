// Define color variables
$tab-bg-color: #ffffff;
$tab-border-color: #e0e0e0;
$tab-active-bg-color: #4caf50; // Green
$tab-active-text-color: #ffffff;
$tab-hover-bg-color: #f0f0f0;
$tab-content-bg-color: #fafafa;
$tab-border-radius: 4px;
$tab-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

$tab-padding: 12px 24px;
$tab-content-padding: 24px;

// Define color variables
$input-border-color: #dcdcdc;
$input-focus-border-color: #4caf50; // Green
$input-bg-color: #ffffff;
$input-text-color: #333;
$input-placeholder-color: #a0a0a0;
$input-border-radius: 4px;
$input-padding: 12px;
$input-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
$input-hover-bg-color: #f9f9f9;

input[type="text"], input[type="number"], textarea {
  background-color: $input-bg-color;
  border: 1px solid $input-border-color;
  border-radius: $input-border-radius;
  padding: $input-padding;
  color: $input-text-color;
  font-size: 16px;
  transition: border-color 0.3s, background-color 0.3s;
  box-shadow: $input-shadow;
  width: 100%;
  box-sizing: border-box;

  &:hover {
    background-color: $input-hover-bg-color;
  }

  &:focus {
    border-color: $input-focus-border-color;
    outline: none; // Remove default focus outline
    box-shadow: 0 0 0 3px rgba(76, 175, 80, 0.2); // Subtle glow effect
  }

  &::placeholder {
    color: $input-placeholder-color;
  }
}

textarea {
  resize: vertical; // Allow vertical resizing only
  min-height: 100px; // Minimum height for textareas
}

// Style for labels
label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
}

// Style for input containers
.input-container {
  margin-bottom: 16px;
}


// .tabs-container {
//   font-family: Arial, sans-serif;

//   .tabs {
//     list-style-type: none;
//     padding: 0;
//     margin: 0;
//     display: flex;
//     border-bottom: 2px solid $tab-border-color;

//     .tab {
//       padding: $tab-padding;
//       cursor: pointer;
//       border: 1px solid $tab-border-color;
//       border-bottom: none;
//       background-color: $tab-bg-color;
//       color: #333;
//       border-radius: $tab-border-radius;
//       transition: background-color 0.3s, color 0.3s;
//       box-shadow: $tab-shadow;

//       &:hover {
//         background-color: $tab-hover-bg-color;
//       }

//       &.active {
//         background-color: $tab-active-bg-color;
//         color: $tab-active-text-color;
//         border-bottom: 2px solid $tab-active-bg-color;
//         box-shadow: none;
//       }
//     }
//   }

//   .tab-content {
//     padding: $tab-content-padding;
//     background-color: $tab-content-bg-color;
//     border: 1px solid $tab-border-color;
//     border-radius: $tab-border-radius;
//     box-shadow: $tab-shadow;
//     margin-top: -1px; // Overlaps the border to give a seamless look
//   }
// }
$tab-padding: 10px 20px;
$tab-border-color: #ddd;
$tab-bg-color: #f9f9f9;
$tab-hover-bg-color: #e9e9e9;
$tab-active-bg-color: #007bff;
$tab-active-text-color: #fff;
$tab-border-radius: 5px 5px 0 0;
$tab-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
$tab-content-padding: 20px;
$tab-content-bg-color: #fff;

.tabs-container {
  font-family: Arial, sans-serif;

  .tabs {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    border-bottom: 2px solid $tab-border-color;
    flex-wrap: wrap;

    .tab {
      padding: $tab-padding;
      cursor: pointer;
      border: 1px solid $tab-border-color;
      border-bottom: none;
      background-color: $tab-bg-color;
      color: #333;
      border-radius: $tab-border-radius;
      transition: background-color 0.3s, color 0.3s;
      box-shadow: $tab-shadow;
      margin-bottom: -1px;

      &:hover {
        background-color: $tab-hover-bg-color;
      }

      &.active {
        background-color: $tab-active-bg-color;
        color: $tab-active-text-color;
        border-bottom: 2px solid $tab-active-bg-color;
        box-shadow: none;
      }
    }
  }

  .tab-content {
    padding: $tab-content-padding;
    background-color: $tab-content-bg-color;
    border: 1px solid $tab-border-color;
    border-radius: $tab-border-radius;
    box-shadow: $tab-shadow;
    margin-top: -1px; // Overlaps the border to give a seamless look
  }

  @media (max-width: 768px) {
    .tabs {
      flex-direction: column;

      .tab {
        border-radius: $tab-border-radius;
        &:not(:last-child) {
          margin-bottom: 1px;
        }

        &.active {
          border-bottom: none;
          border-radius: $tab-border-radius;
        }
      }
    }
  }
}
