/* Add this to your CSS file */
.form-item .signup-link {
    color: #007bff; /* Change to your desired color */
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .form-item .signup-link:hover {
    color: #0056b3; /* Change to a darker shade on hover */
    text-decoration: underline;
  }