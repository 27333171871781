// .category-table-container {
//   padding: 20px;
//   background-color: #f8f9fa;
//   border-radius: 8px;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
// }

// .table-title {
//   font-size: 1.5rem;
//   margin-bottom: 20px;
// }

// .category-list {
//   margin-top: 10px;
// }

// .category-item,
// .subcategory-item {
//   margin: 10px 0;
//   padding: 10px;
//   border: 1px solid #ddd;
//   border-radius: 5px;
//   background-color: #fff;
//   transition: all 0.3s ease;
//   position: relative;
// }

// .category-row,
// .subcategory-row {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
// }

// .category-label,
// .subcategory-label {
//   font-size: 1.2rem;
//   color: #333;
//   flex-grow: 1;
// }

// .inline-edit {
//   width: auto;
//   flex-grow: 1;
//   margin-right: 10px;
// }

// .category-actions {
//   display: flex;
//   gap: 10px;
// }

// .toggle-icon {
//   cursor: pointer;
//   transition: transform 0.3s ease;
// }

// .rotate {
//   transform: rotate(180deg);
// }

// .delete-button {
//   color: red;
// }

// .products-textarea {
//   width: 100%;
//   height: 100px;
// }

// .spinner-container {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100px;
// }
// .category-item.active,
// .subcategory-item.active {
//   border: 2px solid #007bff; /* Change this color to your preferred active color */
//   background-color: rgba(0, 123, 255, 0.1); /* Optional: change background color for active state */
// }
.category-table-container {
  padding: 20px;
  background-color: #f4f5f7;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.table-title {
  font-size: 1.8rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 25px;
}

.category-list {
  margin-top: 15px;
}

.category-item,
.subcategory-item {
  margin: 12px 0;
  padding: 15px;
  border: 1px solid #e1e4e8;
  border-radius: 8px;
  background-color: #ffffff;
  transition: all 0.3s ease;
  position: relative;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

.category-item:hover,
.subcategory-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.category-row,
.subcategory-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.category-label,
.subcategory-label {
  font-size: 1.2rem;
  color: #333;
  font-weight: 500;
  flex-grow: 1;
}

.inline-edit {
  flex-grow: 1;
  margin-right: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.category-actions {
  display: flex;
  gap: 12px;
  align-items: center;
}

.toggle-icon {
  cursor: pointer;
  transition: transform 0.3s ease;
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.rotate {
  transform: rotate(180deg);
}

.delete-button {
  color: #ff4d4f;
  background: none;
  border: none;
  font-size: 1.2rem;
}

.edit-button {
  color: #1890ff;
  background: none;
  border: none;
  font-size: 1.2rem;
}

.products-textarea {
  width: 100%;
  height: 100px;
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
}

.category-item.active,
.subcategory-item.active {
  border: 2px solid #1890ff;
  background-color: rgba(24, 144, 255, 0.1);
}

.styled-button {
  background-color: #1890ff;
  color: #fff;
  border-radius: 4px;
  padding: 8px 15px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.styled-button:hover {
  background-color: #40a9ff;
}

.modal-header {
  background-color: #f4f5f7;
  border-bottom: 1px solid #ddd;
}

.modal-title {
  font-size: 1.5rem;
  font-weight: 500;
}

.modal-body {
  font-size: 1rem;
  color: #666;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
}

.modal-footer .btn {
  border-radius: 4px;
}

.modal-footer .btn-danger {
  background-color: #ff4d4f;
  border-color: #ff4d4f;
}

.modal-footer .btn-danger:hover {
  background-color: #ff7875;
}

.modal-footer .btn-secondary {
  background-color: #d9d9d9;
  border-color: #d9d9d9;
}

.modal-footer .btn-secondary:hover {
  background-color: #bfbfbf;
}
