.add-category-form {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 20px;
  
    .form-row {
      display: flex;
      gap: 10px;
  
      .form-control {
        flex: 1;
        padding: 10px;
        border: 1px solid #ced4da;
        border-radius: 4px;
        font-size: 16px;
        transition: border-color 0.3s;
  
        &:focus {
          border-color: #80bdff;
          outline: none;
          box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
        }
      }
    }
  
    .submit-button {
      padding: 10px 15px;
      background-color: #007bff;
      color: #fff;
      font-size: 16px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s;
  
      &:hover {
        background-color: #0056b3;
      }
    }
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .add-category-form {
      max-width: 100%;
      padding: 15px;
  
      .form-row {
        flex-direction: column;
      }
    }
  }
  