// Define color variables
$background-color: #f9f9f9;
$border-color: #ddd;
$text-color: #333;
$header-bg-color: #e9ecef;
$label-color: #555;
$button-bg-color: #007bff;
$button-hover-bg-color: #0056b3;

// General container styling
.container {

  margin: 0 auto;
}

// Table styles
.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;

  th, td {
    border: 1px solid $border-color;
    padding: 10px;
    text-align: left;
  }

  th {
    background-color: $header-bg-color;
    color: $text-color;
  }

  td {
    background-color: #fff;
  }
}

// Specific table styles
.feature-items-table {
  // Apply general table styles
  @extend .table;

  input.form-control {
    width: 100%;
    padding: 5px;
    border: 1px solid $border-color;
    border-radius: 4px;
  }
}

.fruit-table-container {
  @extend .container;

  h1 {
    margin-bottom: 20px;
    color: $text-color;
  }
}

.fruit-table {
  // Apply general table styles
  @extend .table;

  thead {
    th {
      background-color: $header-bg-color;
      color: $text-color;
    }
  }

  tbody {
    tr {
      &:nth-child(even) {
        background-color: #f2f2f2;
      }
    }

    td {
      .fruit-image {
        width: 50px;
        height: auto;
        border-radius: 4px;
      }
    }
  }
}

// Submit button styling
.submit-button {
  padding: 10px 20px;
  background-color: $button-bg-color;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: $button-hover-bg-color;
  }
}


// SCSS Styles for the Table
.table-container {
    // padding: 20px;
    // max-width: 1000px;
    margin: 0 auto;
  
    h1 {
      margin-bottom: 20px;
      color: $text-color;
    }
  }
  
  .data-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  
    thead {
      background-color: $header-bg-color;
  
      th {
        border: 1px solid $border-color;
        padding: 10px;
        text-align: left;
        color: $text-color;
      }
    }
  
    tbody {
      tr {
        &:nth-child(even) {
          background-color: #f2f2f2;
        }
      }
  
      td {
        border: 1px solid $border-color;
        padding: 10px;
        vertical-align: middle;
  
        .item-image {
          width: 50px;
          height: auto;
          border-radius: 4px;
        }
      }
    }
  }

// Specific table styles
.services-table-container {
    @extend .container;
  
    h1 {
      margin-bottom: 20px;
      color: $text-color;
    }
  }
  
  .services-table {
    @extend .table;
  
    thead {
      th {
        background-color: $header-bg-color;
        color: $text-color;
      }
    }
  
    tbody {
      tr {
        &:nth-child(even) {
          background-color: #f2f2f2;
        }
      }
  
      td {
        .service-content {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
  
        .service-title {
          font-size: 1.5rem;
          margin-bottom: 5px;
        }
  
        .service-subtitle {
          font-size: 1.125rem;
          color: $text-color;
        }
        .service-image{
            width: 80px;
            height: 80px;
        }
  
        .service-item {
          border: 1px solid $border-color;
          border-radius: 4px;
          overflow: hidden;
          text-decoration: none;
          color: inherit;
        }
      }
    }
  }


  
// Specific table styles
.vegetables-table-container {
    @extend .container;
  
    h1 {
      margin-bottom: 20px;
      color: $text-color;
    }
  }
  
  .vegetables-table {
    @extend .table;
  
    thead {
      th {
        background-color: $header-bg-color;
        color: $text-color;
      }
    }
  
    tbody {
      tr {
        &:nth-child(even) {
          background-color: #f2f2f2;
        }
      }
  
      td {
        .item-content {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
  
        .item-title {
          font-size: 1.5rem;
          margin-bottom: 5px;
          color: $text-color;
        }
  
        .item-description {
          font-size: 1rem;
          color: $label-color;
          text-align: center;
        }
  
        .item-price {
          font-size: 1.125rem;
          color: $button-bg-color;
        }
        .item-image{
            width: 80px;
            height: 80px;
        }
  
        .item-link {
          display: block;
          text-decoration: none;
          color: #fff;
          background-color: $button-bg-color;
          border: 1px solid $border-color;
          border-radius: 4px;
          padding: 10px;
          text-align: center;
  
          &:hover {
            background-color: $button-hover-bg-color;
          }
        }
      }
    }
  }

  // Specific table styles
.products-table-container {
    @extend .container;
  
    h1 {
      margin-bottom: 20px;
      color: $text-color;
    }
  }
  
  .products-table {
    @extend .table;
  
    thead {
      th {
        background-color: $header-bg-color;
        color: $text-color;
      }
    }
  
    tbody {
      tr {
        &:nth-child(even) {
          background-color: #f2f2f2;
        }
      }
  
      td {
        .item-content {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
  
        .item-name {
          font-size: 1.5rem;
          margin-bottom: 5px;
          color: $text-color;
        }
  
        .item-price {
          font-size: 1.125rem;
          color: $button-bg-color;
        }
  
        .item-rating {
          font-size: 1.125rem;
          color: $label-color;
        }
        .item-image{
            width: 80px;
            height: 80px;   
        }
  
      
      }
    }
  }