.dropdown-container {
    position: relative;
    margin-bottom: 1em;
    width: 100%; // Ensure full width

    .dropdown-header {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        background-color: #fff;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .arrow {
            margin-left: 10px;
        }
    }

    .dropdown-list {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        border: 1px solid #ccc;
        border-radius: 4px;
        background-color: #fff;
        z-index: 1000;
        max-height: 200px;
        overflow-y: auto;

        .search-input {
            padding: 10px;
            border: none;
            border-bottom: 1px solid #ccc;
            width: 100%;
            box-sizing: border-box;
            outline: none;

            &:focus {
                border-color: #007bff; // Change border color on focus
                box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); // Add shadow for focus effect
            }
        }

        ul {
            padding: 0;
            margin: 0;

            .dropdown-item {
                padding: 10px;
                cursor: pointer;

                &:hover {
                    background-color: #f0f0f0; // Change background on hover
                }
            }

            .no-results {
                padding: 10px;
                color: #999; // Color for no results message
            }
        }
    }
}
